.intro {
    margin: 0 3.5rem 8rem;
    padding-top: 12rem;
    display: flex;
}

.name {
    margin-top: 0.8rem;
    font-weight: bolder;
    font-size: 3rem;
    display: flex;
    flex-wrap: nowrap;
}

.name>span {
    color: var(--yellow);
}
.name>span:nth-child(1) {
    margin-right: 6px;
}

.name>span:nth-child(2) {
    margin-left: 6px;
}

.i-info {
    font-size: 1.5rem;
    margin: 0.5rem 0 2rem;
}

.i-connect {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin: 3.5rem 0;
}

@keyframes slideFromUp {
    0% {
        -webkit-transform: translateY(-50px);
        -ms-transform: translateY(-50px);
        transform: translateY(-50px);
    }

    100% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.i-left {
    flex: 1.5;
    animation: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal forwards running slideFromUp;
}

.i-right {
    flex: 1;
}

.i-shape {
    position: absolute;
    right: 0;
    top: 50px;
    width: 40%;
}

.i-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    animation: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal forwards running slideFromUp;
}

.i-box>div {
    padding: 30px;
    border-radius: 10px;
    background-color: var(--dgray);
    box-shadow: rgb(0 0 0 / 5%) 0px 5px 10px;
}

.i-box>div>p {
    padding: 5px 0 5px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media (max-width:481px) {
    .name {
        flex-direction: column;
    }

    .intro {
        display: block;
        margin: 8rem 1.4rem 1rem;
        padding: 0;
    }

    .i-shape {
        top: 0;
    }

    .i-name {
        text-align: center;
    }

    .i-info {
        font-size: 1rem;
    }

    .i-connect {
        justify-content: center;
        margin: 3rem;
    }

    .i-box {
        width: 100%;
        font-size: 0.9rem;
    }
}